@use "./assets/variables.scss" as *;


body {
  margin: 0;
}

.components-wrapper {
  border: 1px dashed black;
  padding: 10px;
}

.btn-primary {
  color: $color-white;
  background: -moz-linear-gradient(
    left,
    rgba(33, 43, 96, 1) 0%,
    rgba(33, 43, 96, 1) 33%,
    rgba(55, 72, 159, 1) 66%,
    rgba(55, 72, 159, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(33, 43, 96, 1) 0%,
    rgba(33, 43, 96, 1) 33%,
    rgba(55, 72, 159, 1) 66%,
    rgba(55, 72, 159, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(33, 43, 96, 1) 0%,
    rgba(33, 43, 96, 1) 33%,
    rgba(55, 72, 159, 1) 66%,
    rgba(55, 72, 159, 1) 100%
  );
  -webkit-background-size: 300% 100%;
  background-size: 300% 100%;
  transition: all 0.6s ease-out;
  border: 1px solid transparent;
  border-radius: 22px;

  &:hover {
    background-color: $color-st-tropaz;
    border-color: $color-mobile-light;
    background-position: 100% 0;
  }
}

body {
  .form-control:focus,
  .form-select:focus {
    border-color: $color-cornflower-blue;
    outline: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }

  & .disabled-link {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

     .sidebar h3 {
       margin: 0 0 30px;
       font-size: 45px;
       line-height: 50px;
       font-weight: 500;
       font-family: $font-primary;
       color: $color-periwinkle-gray;

       @media screen and (max-width: 1280px){
        font-size: 35px;
        line-height: 39px;
       }

   }
  & .checkbox .lbl {
    &:before {
      border: 2px solid $color-white;
    }
    &:after {
      background: url(./assets/images/ico-check-white.svg) no-repeat 50% 50%;
    }
  }

  & .checkbox {
    vertical-align: text-top;
  }

  & input[type="checkbox"][disabled] + .lbl:before {
    border: 2px solid $color-gray;
    background-color: $color-gallery;
  }
  & input[type="checkbox"][disabled] + .lbl:after {
    background: url(./assets/images/ico-check-disabled.svg) no-repeat 50% 50%;
  }

  & .checkbox {
    & .lbl:after {
      top: 3px;
      left: 2px;
    }
    & input {
      cursor: pointer;
    }
  }

  & .checkbox input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 20px;
    height: 100%;
    opacity: 0;
    margin: 0;
  }

  & .checkbox .lbl:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    border: 2px solid $color-primary;
    border-radius: 3px;
    z-index: 1;
    top: 1px;
  }

  & .checkbox .lbl:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 2px;
    background: url(./assets/images/ico-check.svg) no-repeat 50% 50%;
    width: 15px;
    height: 14px;
    -webkit-background-size: 15px 14px;
    background-size: 15px 14px;
    z-index: 5;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  & .checkbox input[type="checkbox"]:checked + .lbl:after {
    opacity: 1;
    visibility: visible;
  }

  & .checkbox .lbl {
    width: auto;
    margin: 0;
    padding: 0 0 0 20px;
    min-height: 20px;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    position: relative;
    cursor: pointer;
  }

  & .checkbox {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sidebar{

    & .trades-list {
       list-style: none;
       margin: 0 0 50px;
       padding: 12px 17px;
       font: 500 15px/19px $font-primary;
       -webkit-box-shadow: 0 2px 13px rgb(204 204 204 / 35%);
       box-shadow: 0 2px 13px rgb(204 204 204 / 35%);

       & li + li {
          border-top: 1px solid $color-primary;
      }


       &  a {
          display: block;
          position: relative;
          padding: 16px 30px 18px 0;
          color: $color-primary;
          text-decoration: none;
      }

      & a:hover, & a:focus {
       text-decoration: none;
       color: $color-primary-light;
      }

      & a [class*="fa-chevron"] {
       position: absolute;
       top: 50%;
       right: 12px;
       margin-top: -11px;
       text-rendering: auto;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
      }
   }
 }

}
